









































































































































































































import Vue from 'vue';
import * as Api from '@/api/equityCenter';
import moment from 'moment';
const columns = [
  {
    title: '权益名称',
    dataIndex: 'rightsName',
    key: 'rightsName',
    align: 'center',
  },
  {
    title: '权益类型',
    dataIndex: 'rightsType',
    key: 'rightsType',
    align: 'center',
    scopedSlots: { customRender: 'rightsType' },
  },
  {
    title: '权益说明',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },
  {
    title: '创建人',
    dataIndex: 'extend1',
    key: 'extend1',
    align: 'center',
  },
  {
    title: '创建时间',
    dataIndex: 'recDateStr',
    key: 'recDateStr',
    align: 'center',
  },
  {
    title:'权益状态',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
];
export default Vue.extend({
  data() {
    return {
      tableLoading: false,
      formState: {
        extend1: '',
        rightsName: '',
        rightsType: '',
        status: '',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showQuickJumper: true,
        showTotal: (total: number) => {
          return `共 ${total} 条`;
        },
      },
      dataSource: [],
      columns,
      visible: false,
      stVisible: false,
      deleteVisible: false,
      enVisible: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      applyTime: [],
      loading: false,
      form: {
        rightsName: '',
        rightsType: '',
        description: '',
      },
      rules: {
        rightsName: [
          {
            required: true,
            message: '请输入权益名称',
            trigger: 'blur',
          },
          {
            min: 0,
            max: 50,
            message: '角色名称应不超过50个字符',
            trigger: 'blur',
          },
        ],
        rightsType:[
          {
            required: true,
            message: '请选择权益类型',
            trigger: 'change',
          },
        ],
        description: [
          {
            min: 0,
            max: 200,
            message: '权益说明不超过200个字符',
            trigger: 'blur',
          },
        ],
      },
      disabled: false,
      checked: {},
      tempId: '',
      checkList: [],
      conditionOps: {},
      modaltt:''
    };
  },
  computed: {},
  mounted() {
    this.getRightsList();
  },
  methods: {
    moment,
    getRightsList() {
      this.tableLoading = true;
      const param = {
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        rightsName: this.formState.rightsName,
        rightsType: this.formState.rightsType,
        extend1: this.formState.extend1,
        status: this.formState.status,
        startTime: this.applyTime[0],
        endTime: this.applyTime[1],
      };
      Api.getRightsByPage(param)
        .then((res) => {
          if (res.status === 200) {
            this.dataSource = res.result.list;
            this.pagination.total = res.result.total;
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 组合查询表单提交
    handleSearchSubmit() {
      this.pagination.current = 1;
      this.getRightsList();
    },
    // 组合查询表单重置
    handleSearchReset() {
      (this.$refs.searchFormRef as HTMLFormElement).resetFields();
      this.pagination.current = 1;
      this.applyTime = [];
      this.getRightsList();
    },
    // 主列表翻页控制
    handleTableChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getRightsList();
    },
    // 删除
    delHd(id) {
      this.tempId = id
      this.deleteVisible = true;
    },
    deleteSure() {
      Api.rightsDelete(this.tempId).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('删除成功');
          this.deleteVisible = false;
          this.handleSearchSubmit();
        } else {
          this.$message.error(data.message);
        }
      });
    },
    // 停用
    // 停用
    stopHd(id) {
      this.tempId = id;
      this.stVisible = true;
    },
    stopSure() {
      Api.rightsStop(this.tempId).then((res) => {
        const data = res;
        if (data.status === 200) {
          this.$message.success('停用成功');
          this.stVisible = false;
          this.handleSearchSubmit();
          this.tempId = ''
        } else {
          this.$message.error(data.message);
        }
      });
    },
    // 启用
    enableHd(id) {
      this.tempId = id
      this.enVisible = true;
    },
    enableSure() {
      Api.rightsEnable(this.tempId).then((res) => {
        if (res.status === 200) {
          this.$message.success('启用成功');
          this.enVisible = false;
          this.handleSearchSubmit();
          this.tempId = ''
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 回显
    getQueryInfo(id){
      this.getCondition()
      this.visible = true;
      this.disabled = true;
      this.modaltt = '编辑平台权益'
      this.checkList = []
      Api.rightsQuery(id)
        .then((res) => {
          if (res.status === 200) {
            this.form = res.result;
            res.result.rightsDiscountItemDTOList.map((temp) => {
              this.checked[temp.discountItemType] = temp.discountItemNbr
              let newOps = this.conditionOps[temp.discountItemType].find(item=>item.sequenceNbr == temp.discountItemNbr)
              newOps.discountItemValue = temp.discountItemValue
              newOps.discountItemUnit = temp.discountItemUnit

              if(temp.discountItemType == 1){
                this.checkList.push({...temp,key:temp.discountItemType,id:temp.discountItemNbr,label:`单套赠送 ${temp.discountItemValue} 月延期使用`, discountItemUnit: temp.discountItemUnit,title:temp.discountItemName})
              }else if(temp.discountItemType == 0){
                this.checkList.push({...temp,key:temp.discountItemType,id:temp.discountItemNbr,label:`订单优惠 ${temp.discountItemValue} 折`, discountItemUnit: temp.discountItemUnit,title:temp.discountItemName})
              }else if (temp.discountItemType == 2){
                this.checkList.push({...temp,key:temp.discountItemType,id:temp.discountItemNbr,label:`赠送代金券 ${temp.discountItemValue} 元`, discountItemUnit: temp.discountItemUnit,title:temp.discountItemName})
              }
            })
          }
        })
    },
    // 新增
    showModal() {
      this.form.rightsType = '';
      this.form.rightsName = '';
      this.form.description = '';
      this.modaltt = '新增平台权益'
      this.visible = true;
      this.disable = false
      this.getCondition()
    },
    // 提交
    handleOk() {
      (this.$refs.ruleForm as HTMLFormElement).validate((valid) => {
        if (valid) {
          this.loading = true;
          if(this.disabled){
            this.updateApiHd()
          }else{
            this.addApiHd()
          }
        } else {
          return false;
        }
      });
    },
    addApiHd(){
      this.form.rightsDiscountItemDTOList = this.checkList.map((item) => {
        return {
          discountItemNbr: item.sequenceNbr,
          discountItemType: item.discountItemType,
          discountItemValue: item.discountItemValue,
          discountItemUnit: item.discountItemUnit,
        }
      })
      Api.addRights(this.form)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('平台权益新增成功！');
            this.loading = false;
            this.visible = false;
            this.handleSearchSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateApiHd(){
      Api.updateRights(this.form)
        .then((res) => {
          if (res.status === 200) {
            this.$message.success('平台权益编辑成功！');
            this.loading = false;
            this.visible = false;
            this.handleSearchSubmit();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      (this.$refs.ruleForm as HTMLFormElement).resetFields();
      this.visible = false;
    },
    handleCancel() {
      this.visible = false;
    },
    getCondition(){
      Api.getConditionList()
        .then((res) => {
          if (res.status === 200) {
            this.conditionOps = res.result;
          }
        })
    },
    changeChecked1(key) {
      let temp = this.conditionOps[key].filter(item=>item.sequenceNbr == this.checked[key])[0]
      if(key == 1){
        this.checkList.push({...temp,key,id:temp.sequenceNbr,label:`单套赠送 ${temp.discountItemValue} 月延期使用`, discountItemUnit: 1,title:temp.discountItemName})
      }else if(key == 0){
        this.checkList.push({...temp,key,id:temp.sequenceNbr,label:`订单优惠 ${temp.discountItemValue} 折`, discountItemUnit: 0,title:temp.discountItemName})
      }else if (key == 2){
        this.checkList.push({...temp,key,id:temp.sequenceNbr,label:`赠送代金券 ${temp.discountItemValue} 元`, discountItemUnit: 2,title:temp.discountItemName})
      }
    },
    removeCheckList(line){
      this.checkList.forEach(item=>{
        if(item.id==line.id){
          this.checkList.splice(this.checkList.indexOf(item),1)
        }
      })
      this.checked[line.discountItemType] = ''
    }
  },
});
