import http from '@/utils/http';

//  列表查询
export function getRightsByPage(formdata: any): any {
  return http.get(`/portal-resources/1/portalOperateRights/queryByPage`, formdata);
}


//  条件查询
export function getConditionList(): any {
  return http.get(`/portal-resources/1/portalOperateRights/discountItemList`);
}

// 新增
export function addRights(formdata: any): any {
  return http.post(`/portal-resources/1/portalOperateRights/add`, formdata);
}


// 编辑
export function updateRights(formdata: any): any {
  return http.post(`/portal-resources/1/portalOperateRights/update`, formdata);
}

// 启用
export function rightsEnable(id: any): any {
  return http.get(`/portal-resources/1/portalOperateRights/enable/${id}`);
}

// 停用
export function rightsStop(id: any): any {
  return http.get(`/portal-resources/1/portalOperateRights/stop/${id}`);
}

// 删除
export function rightsDelete(id: any): any {
  return http.get(`/portal-resources/1/portalOperateRights/deleteById/${id}`);
}

// 回显
export function rightsQuery(id: any): any {
  return http.get(`/portal-resources/1/portalOperateRights/queryById/${id}`);
}